"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NormalAssetType = exports.CoupledAssetType = void 0;
var NormalAssetType;
exports.NormalAssetType = NormalAssetType;

(function (NormalAssetType) {
  NormalAssetType[NormalAssetType["Car"] = 100] = "Car";
  NormalAssetType[NormalAssetType["Van"] = 101] = "Van";
  NormalAssetType[NormalAssetType["Depot"] = 102] = "Depot";
  NormalAssetType[NormalAssetType["Device"] = 103] = "Device";
  NormalAssetType[NormalAssetType["SwapBody"] = 104] = "SwapBody";
  NormalAssetType[NormalAssetType["TractorUnit"] = 105] = "TractorUnit";
  NormalAssetType[NormalAssetType["TruckTarpaulin"] = 110] = "TruckTarpaulin";
  NormalAssetType[NormalAssetType["TruckBox"] = 111] = "TruckBox";
  NormalAssetType[NormalAssetType["TruckReeferBox"] = 112] = "TruckReeferBox";
  NormalAssetType[NormalAssetType["TruckInterchangeable"] = 113] = "TruckInterchangeable";
  NormalAssetType[NormalAssetType["TruckTipper"] = 114] = "TruckTipper";
  NormalAssetType[NormalAssetType["TruckTanker"] = 115] = "TruckTanker";
  NormalAssetType[NormalAssetType["SemitrailerTarpaulin"] = 120] = "SemitrailerTarpaulin";
  NormalAssetType[NormalAssetType["SemitrailerBox"] = 121] = "SemitrailerBox";
  NormalAssetType[NormalAssetType["SemitrailerReeferBox"] = 122] = "SemitrailerReeferBox";
  NormalAssetType[NormalAssetType["SemitrailerInterchangeable"] = 123] = "SemitrailerInterchangeable";
  NormalAssetType[NormalAssetType["SemitrailerTipper"] = 124] = "SemitrailerTipper";
  NormalAssetType[NormalAssetType["SemitrailerTanker"] = 125] = "SemitrailerTanker";
  NormalAssetType[NormalAssetType["TrailerTarpaulin"] = 130] = "TrailerTarpaulin";
  NormalAssetType[NormalAssetType["TrailerBox"] = 131] = "TrailerBox";
  NormalAssetType[NormalAssetType["TrailerReeferBox"] = 132] = "TrailerReeferBox";
  NormalAssetType[NormalAssetType["TrailerInterchangeable"] = 133] = "TrailerInterchangeable";
  NormalAssetType[NormalAssetType["TrailerTipper"] = 134] = "TrailerTipper";
  NormalAssetType[NormalAssetType["TrailerTanker"] = 135] = "TrailerTanker";
  NormalAssetType[NormalAssetType["RailCarIntermodal"] = 140] = "RailCarIntermodal";
  NormalAssetType[NormalAssetType["RailCarBox"] = 141] = "RailCarBox";
  NormalAssetType[NormalAssetType["RailCarTank"] = 142] = "RailCarTank";
  NormalAssetType[NormalAssetType["RailCarBulk"] = 143] = "RailCarBulk";
  NormalAssetType[NormalAssetType["RailCarPassenger"] = 144] = "RailCarPassenger";
  NormalAssetType[NormalAssetType["RailCarTankContainer"] = 2000] = "RailCarTankContainer";
  NormalAssetType[NormalAssetType["Locomotive"] = 2001] = "Locomotive";
  NormalAssetType[NormalAssetType["Container"] = 150] = "Container";
})(NormalAssetType || (exports.NormalAssetType = NormalAssetType = {}));

var CoupledAssetType;
exports.CoupledAssetType = CoupledAssetType;

(function (CoupledAssetType) {
  CoupledAssetType[CoupledAssetType["TruckTrailer"] = 200] = "TruckTrailer";
  CoupledAssetType[CoupledAssetType["Group"] = 210] = "Group";
  CoupledAssetType[CoupledAssetType["Train"] = 240] = "Train";
  CoupledAssetType[CoupledAssetType["TrainBox"] = 241] = "TrainBox";
  CoupledAssetType[CoupledAssetType["TrainTank"] = 242] = "TrainTank";
  CoupledAssetType[CoupledAssetType["TrainPassenger"] = 244] = "TrainPassenger";
  CoupledAssetType[CoupledAssetType["TrainIntermodal"] = 245] = "TrainIntermodal";
})(CoupledAssetType || (exports.CoupledAssetType = CoupledAssetType = {}));