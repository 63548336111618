"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PoiCategoryIcon = void 0;
var PoiCategoryIcon;
exports.PoiCategoryIcon = PoiCategoryIcon;

(function (PoiCategoryIcon) {
  PoiCategoryIcon["Default"] = "default";
  PoiCategoryIcon["TrainStation"] = "trainStation";
  PoiCategoryIcon["Factory"] = "factory";
  PoiCategoryIcon["Airport"] = "airport";
  PoiCategoryIcon["Harbor"] = "harbor";
  PoiCategoryIcon["Business"] = "business";
  PoiCategoryIcon["Customer"] = "customer";
  PoiCategoryIcon["Warehouse"] = "warehouse";
  PoiCategoryIcon["GasStation"] = "gasStation";
  PoiCategoryIcon["Workshop"] = "workshop";
  PoiCategoryIcon["RailWorkshop"] = "railWorkshop";
  PoiCategoryIcon["Carrier"] = "carrier";
  PoiCategoryIcon["Schmitz"] = "schmitz";
  PoiCategoryIcon["Thermoking"] = "thermoking";
  PoiCategoryIcon["Border"] = "border";
  PoiCategoryIcon["ParkingAllowed"] = "parkingAllowed";
  PoiCategoryIcon["ParkingForbidden"] = "parkingForbidden";
  PoiCategoryIcon["MobileTeam"] = "mobileTeam";
  PoiCategoryIcon["ExchangePoint"] = "exchangePoint";
  PoiCategoryIcon["Temporary"] = "temporary";
})(PoiCategoryIcon || (exports.PoiCategoryIcon = PoiCategoryIcon = {}));